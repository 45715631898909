@media screen and (max-width:468px){
    .calender{
        margin-left: -5%;
    }
    .inputDate1{
        padding: 5px;
        width: 150px;
        color: gray;
        border: 1px solid gray;
        border-radius: 3px;
        margin: 5px;
    }

    .inputDate1:focus{
        outline: none;
    }
    .tHeading3{
        font-size: 12px !important;
    }
    .downloadBtn{
        padding: 5px;
        margin: 10px;
        border-radius: 5px;
        color: white;
        border: none;
        background-color: rgb(90, 150, 197);
    }
    .downloadBtn:hover{
        background-color: rgb(44, 114, 168);
    }
}
@media screen and (min-device-width:768px){
    .mobileScreen{
        display: none;
    }
}
@media screen and (max-width:468px){
    .bigScreen{
        display: none;
    }
    .riderStats{
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 5%;
    }
    .ordersBody{
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 5%;
    }
    .stats{
        display: flex;
        margin-top: 3%;
        justify-content: space-between;
    }
    .statBox{
        padding: 10px;
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        width: 180px;
        background-color: white;
        
    }
    .boxHeader{
        text-align: left;
        font-weight: 500;
        color: rgb(61, 61, 61);
    }
    .ordersNumber{
        font-size: 22px;
        font-weight: 500;
        margin-top: -3%;
    }
    .totalOrders{
        font-size: 13px;
        color: gray;
        text-align: left;
        margin-top: -3%;
    }
    .activityTitle{
        text-align: left;
        font-size: 17px;
        font-weight: 500;
        color: rgb(64, 63, 63);
    }
    .unplannedOrders{
        margin-top: 3%;
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(255, 0, 0, 0.095);
    }
    .unplannedOrders2{
        margin-top: 3%;
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(17, 0, 255, 0.095);
    }
    .unplannedOrders3{
        margin-top: 3%;
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(206, 238, 0, 0.095);
    }
    .unplannedOrders4{
        margin-top: 3%;
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(5, 132, 31, 0.095);
    }
    .tHeading{
        color: rgb(71, 71, 71) !important;
        font-size: 14px;
        font-weight: 500;
    }
    .tbutton{
        background-color: white;
        border: none;
        font-size: 14px;
        color: rgb(71, 71, 71) !important;
    }
    .tHeading2{
        color: rgb(83, 83, 83) !important;
        font-size: 14px;
        margin-top: 2%;

        
    }

    .unplannedHeading{
        text-align: left;
        margin-left: 4%;
        color: rgb(95, 95, 95) !important;
        font-weight: 500;
    }
    .modalStyling{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        background-color: white;
        border: none;
        padding: 20px;
        border-radius: 5px;
       
    }
    .addStoreButton{
        background-color: rgb(56, 97, 174);
        border: none;
        padding: 2px 8px 2px 8px;
        color: white;
        margin-top: 4%;
       
    }
    #standard-basic2{
        width: 300px;
        font-size: 13px;
    }
    .addStoreButton2{
        background-color: rgb(165, 31, 31);
        border: none;
        padding: 2px 8px 2px 8px;
        color: white;
        margin-top: 4%;
        margin-left: 6%;
    }
    .editBTN{
        color: rgb(255, 174, 24) !important;
        background-color: white;
        border: none;
        size: 3px;
        font-size: 12px !important;
    }
    .blockBTN{
        color: rgb(18, 175, 200) !important;
        background-color: rgb(255, 255, 255);
        border: none;
        font-size: 12px !important;
    }
    .deleteBTN{
        color: rgb(217, 5, 5) !important;
        background-color: white;
        border: none;
        font-size: 12px !important;
    }
    .assignButtons{
        margin-top: 8%;
        display: flex;
    }
}
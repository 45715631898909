@media screen and (max-width:468px){
    .orderDet{
        margin-top: 3%;
        display: flex;
        margin-left: 2%;
    }
    .orderDetLeft{
        flex: 6;
    }
    .orderDetRight{
        flex: 6;
    }
    .ordersP{
        margin-top: 2%;
        font-size: 14px;
        text-align: left;
    }
    .call{
        margin-left: -45%;
       color: white;
       padding: 10px;
       border-radius: 5px;
       margin-top: 5%;
       position: absolute;
        background-color: green;
        
    }
    .routeButtons{
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        width: 15%;
        margin-left: 24%;
    }
   
}